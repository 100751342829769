.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #ddd;
}

.google-sign-in-button {
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apple-sign-in-button {
    background-color: #FFF;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-sign-in-button img,
.apple-sign-in-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.login-info {
    font-size: 12px;
    color: #555;
    text-align: center;
}
