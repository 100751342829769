.send-data-container {
    text-align: center;
    margin: 20px 0;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    width: 90%; /* Make it responsive */
    max-width: 600px; /* Set a max-width */
    margin: 20px auto; /* Center align */
  }
  
  .send-data-container h2 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 20px; /* Smaller text size */
  }
  
  .send-data-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack items vertically on mobile */
  }
  
  .send-data-input {
    margin-bottom: 10px; /* Add margin to bottom */
    padding: 8px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #2e2e2e;
    color: #ffffff;
    font-size: 14px; /* Smaller text size */
    width: 100%; /* Full width on mobile */
    max-width: 400px; /* Max width for larger screens */
  }
  
  .send-data-button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #ff8c00; /* Orange color */
    color: black;
    cursor: pointer;
    font-size: 14px; /* Smaller text size */
  }
  
  .send-data-button:hover {
    background-color: #e07b00;
  }
  
  .response-message {
    margin-top: 20px;
    font-size: 14px;
    color: #ff8c00; /* Orange color for the message text */
  }
  