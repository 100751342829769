.danger-zone {
    margin-top: 40px;
    border-top: 1px solid #ff3333;
    padding-top: 20px;
  }
  
  .danger-zone h2 {
    color: #ff3333;
    margin-bottom: 20px;
  }
  
  .btn-delete {
    background-color: #ff6666;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 600px;
    color: black;
  }
  
  .modal-buttons {
    margin-top: 20px;
  }
  
  .btn-confirm-delete {
    background-color: #ff3333;
    color: white;
    margin: 10px;
  }
  
  .btn-cancel {
    background-color: #ff8c00;
    color: black;
    margin: 10px;
  }
  