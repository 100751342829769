body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
}

/* Styling for buttons */
button {
  width: 85%;
  max-width: 100%;
  margin: 10px 0;
  border: none;
  background-color: #FF6E2A;
  color: #0F0011;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #FF8C00;
}

/* Error message styling */
p {
  color: #ff3333;
  text-align: center; /* Center align the text */
}

/* Container styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

/* Ensuring full height for layout */
.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }

  button {
    width: 100%;
  }
}
