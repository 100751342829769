.subscription-container {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Quicksand', sans-serif;
    justify-content: space-around;
  }
  
  .subscription-box {
    background-color: #1e1e1e;
    border: 1px solid #ff8c00;
    border-radius: 10px;
    margin: 10px;
    width: 150px; /* Reduce width */
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .subscription-box:hover {
    transform: scale(1.05);
  }
  
  .subscription-box h3 {
    margin-bottom: 10px;
    color: #ff8c00;
  }
  
  .subscription-box p {
    color: #ffffff;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 600px) {
    .subscription-container {
      flex-direction: column;
      align-items: center;
    }
  
    .subscription-box {
      width: 90%; /* Make boxes take full width on mobile */
    }
  }
  