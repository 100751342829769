

.layout {
    display: flex;
    width: 100%;
    max-width: 1200px;
    min-height: 70vh;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    flex-direction: column;
}

.sidebar {
    width: 80vw;
    height: 100vh; /* Take full height on mobile */
    background-color: #1e1e1e;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-header {
    margin-bottom: 20px;
}

.sidebar h2 {
    margin: 0;
    color: #ff8c00;
    text-align: center;
}

.sidebar p {
    margin: 0;
    color: #ffffff;
    text-align: left;
}

.sidebar nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar nav ul li {
    margin: 20px 0;
}

.sidebar nav ul li button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
    width: 100%;
    text-align: left;
}

.sidebar nav ul li button:hover {
    color: #ff8c00;
}

.btn-logout {
    background-color: #ff8c00;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: auto;
}

.content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.home-container {
    width: 70%;
    max-width: 600px;
    padding: 30px;
    text-align: center;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #1e1e1e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    flex-shrink: 0;
}

.home-container h1 {
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.button-group {
    margin-top: 20px;
}

.user-info {
    margin-top: 20px;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
    color: #ffffff;
    text-align: left;
    font-size: 12px;
}

/* Media queries for mobile responsiveness */
@media (min-width: 600px) {
    .layout {
        flex-direction: row;
    }

    .sidebar {
        width: 250px;
        position: relative;
        transform: translateX(0);
        max-height: 100vh; /* Ensure it takes full height on desktop */
        height: auto;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    }

    .content {
        margin-top: 0;
    }

    .home-container {
        max-width: 70%;
        padding: 50px;
    }
}

.sidebar-toggle {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: none;
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

/* Hide toggle button on larger screens */
@media (min-width: 600px) {
    .sidebar-toggle {
        display: none;
    }
}
