.account-data-container {
    max-width: 700px; /* Ensure consistent max-width */
    margin: 0 auto;
    padding: 20px; /* Reduce padding for smaller screens */
    text-align: center;
    border: 1px solid #333;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #1e1e1e;
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .account-data-container h1 {
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .account-data-container h2 {
    color: #ff8c00;
  }
  
  .account-data-container p {
    color: #ffffff;
    margin: 10px 0;
    text-align: left; /* Align text to the left */
  }
  
  .account-data-container pre {
    text-align: left; /* Align text to the left */
  }
  
  .data-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  /* Media query to stack boxes vertically on smaller screens */
  @media (max-width: 600px) {
    .data-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .data-section {
    padding: 15px;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #282828;
  }
  
  .data-section h2 {
    color: #ff8c00;
    border-bottom: 1px solid #ff8c00;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .data-section p {
    margin: 5px 0;
    color: #cccccc;
  }
  
  .data-section p strong {
    color: #ffffff;
  }
  